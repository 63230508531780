/* -----------------
   CRUSHES COMPONENT
   ----------------- */

.crushes {
  .crush--spotlight {
    margin-bottom: 40px;

    @media (min-width: $mq-md) {
      margin-bottom: 30px;
    }
  }

  .crush--big-on-mobile,
  .crush--small {
    margin-bottom: 20px;

    @media (min-width: $mq-sm) {
      margin-bottom: 30px;
    }
  }
}

.crushes__title {
  text-align: center;
}

// 3 columns by default

.crushes__list {
  @media (min-width: $mq-sm) {
    lost-flex-container: row;
  }
  .crush {
    @media (min-width: $mq-sm) {
      lost-column: 1/2 2 12px;
    }
    @media (min-width: $mq-md) {
      lost-column: 1/3 3 12px;
    }
  }
}

.crushes__list.crushes__list--two {
  @media (min-width: $mq-sm) {
    lost-flex-container: row;
  }
  .crush {
    @media (min-width: $mq-sm) {
      lost-column: 1/2 2 12px;
      margin-bottom: 0;
    }
  }
}

.crushes__list.crushes__list--three {
  @media (min-width: $mq-sm) {
    lost-flex-container: row;
  }
  .crush {
    @media (min-width: $mq-sm) {
      lost-column: 1/2 2 12px;
    }
    @media (min-width: $mq-md) {
      lost-column: 1/3 3 12px;
      min-width: 200px;
    }
  }
}

#root > div > main > div.crushes.francebleu > div > div > article{
  margin: auto
}

.crushes__message {
  @extend %title-h1;
}
