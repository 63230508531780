/* ----------------------
   CURRENT DATE COMPONENT
   ---------------------- */

.current-date {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.current-date__txt {
  @include font(12, 4, $lato);
  display: block;
  margin-bottom: 6px;
}

.current-date__number {
  @include font(20, 4, $latoBold);
  display: block;
  margin-bottom: 6px;
  color: $blackish;
}

.current-date__month {
  @include font(16, 4, $latoBold);
  text-transform: capitalize;
  display: block;
  color: $blackish;
}
