/* --------------
   FORM COMPONENT
   -------------- */

.form__row {
  margin-bottom: 20px;

  &.form__row--submit {
    margin-top: 30px;
    text-align: center;

    @media (min-width: $mq-md) {
      margin-top: 45px;
    }
  }
}

.form__label {
  &.form__label--outside {
    display: inline-block;
    margin-bottom: 8px;
  }
}

.form__row.form__row--submit > button:disabled{
  background: #ccc;
  cursor: not-allowed;
}

.form__error, form > div > div > span {
  @include font(12, 6, $latoBold);
  margin: 4px 0;
  color: $red;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

/* recaptcha */
.grecaptcha-badge { visibility: hidden; }

.form__row.recaptcha{
  text-align: center;
}

.formSection{
  margin-bottom: 15px;
  h2 {
    font-size: 17px;
  }
}

select#pays {
  font: normal 300 1.8rem/1.34 "Lato", sans-serif;
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid #dbdbdb;
  -webkit-box-shadow: 0 2px 16px 0 #ececec;
  box-shadow: 0 2px 16px 0 #ececec;
  color: #474747;
  background: #fff;
}