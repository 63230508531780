/* ------------
   REGISTER PAGE
   ------------ */

.iframeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
#iframe__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 100%;
  min-height: 430px;
  padding: 0 0 30px 0;
  @media (max-width: $mq-sm) {
    width: 100%;
    min-height: 300px;
  }
}

#iframe__form {
  min-height: 1200px;
}

.formExpl {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  p {
    font-size: 10px;
    a {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}
