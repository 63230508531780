/* --------------
   BLOG COMPONENT
   Force properties because of the wordpress styles import
   -------------- */

body {
  background-color: $white !important;
}

a.is-inactive,
nav > ul > li:nth-child(1) > a,
p.footer__bottom a,
a.crush__link {
  color: $greyDark !important;
}

a.header__nav-link.blog {
  // color: $red;
  @media (min-width: $mq-md) {
    padding: 0;
    padding-top: 8px;
    text-align: right;
  }
}

.post-content ul {
  list-style-type: disc;
  list-style-position: inside;
}

.post-content ol {
  list-style-type: decimal;
  list-style-position: inside;
}

.post-content ul ul,
.post-content ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}

.post-contentol ol,
.post-content ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}

.post-content li {
  display: list-item;
}

.main--results.blog {
  margin-top: 40px;
}

.main--blog .crushes__title {
  color: $red;
}

.main--blog {
  max-width: 1200px;
  margin: auto;
  .sub-header {
    lost-center: 10/12 no-flex;
    margin-bottom: 45px;

    @media (min-width: $mq-md) {
      margin-bottom: 55px;
    }
  }
  a.link {
    font-weight: 700;
    text-decoration: underline;
  }
  .generic-content {
    lost-center: 10/12 no-flex;
    margin-bottom: 30px;

    @media (min-width: $mq-md) {
      lost-center: 8/12 no-flex;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 55px;
    }
  }
  .blog-img {
    margin: auto;
  }
}
