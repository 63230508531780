/* ------------
   CONTACT PAGE
   ------------ */

.main--abonnement {
  .sub-header {
    lost-center: 10/12 no-flex;
    margin-bottom: 45px;

    @media (min-width: $mq-md) {
      margin-bottom: 55px;
    }
  }

  .form {
    margin-bottom: 45px;

    @media (min-width: $mq-md) {
      margin-bottom: 55px;
    }
  }

  .form__inner {
    lost-center: 8/12 no-flex;

    @media (min-width: $mq-md) {
      lost-center: 500px no-flex;
    }
  }
}
