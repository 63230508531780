/* --------------------
   SUB-HEADER COMPONENT
   -------------------- */

.sub-header {
  @include font(18, 6, $lato);
  text-align: center;
}

.sub-header__inner {
  position: relative;
  text-align: center;
  padding: 10px 10px;

  @media (min-width: $mq-md) {
    padding: 10px 20px;
  }
}

.sub-header__inner.has-border {
  border-bottom: 1px dashed $greyLight;
}

.sub-header__title {
  @extend %title-h1;
}

.sub-header__body {
  @extend %title-h1;
  font-weight: 300;
}
.sub-header__city {
  @include font(10, 3, $lato);
}
.sub-header__link {
  @include font(12, 3, $lato);
  position: absolute;
  width: auto;
  height: 16px;
  left: 0;
  top: 50%;
  transform: translateY(18px);

  @media (min-width: $mq-md) {
    top: 40%;
    transform: none;
    width: auto;
    height: auto;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: -100px;
    background: url("img/ico-arrow-left.svg") center center no-repeat;
    background-size: contain;

    @media (min-width: $mq-md) {
      top: 50%;
      transform: translateY(-50%);
      left: -18px;
      width: 6px;
      height: 12px;
    }
  }
}
