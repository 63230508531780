/* ---------------
   Generic classes
   --------------- */

body {
  .light {
    font-weight: 300;
  }

  .bold {
    font-weight: 700;
  }

  .ta--c {
    text-align: center;
  }

  .hide-desktop {
    @media all and (min-width: $mq-md) {
      display: none;
    }
  }

  .hide-mobile {
    display: none;

    @media all and (min-width: $mq-md) {
      display: block;
    }
  }

  .hide-tablet {
    @media all and (min-width: $mq-md) {
      display: none;
    }
    @media (--minLarge) {
      display: block;
    }
  }

  .show-extra-large {
    display: none;

    @media all and (min-width: $mq-xl) {
      display: block;
    }
  }
}

/* ----------
   ANIMATIONS
   ---------- */

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
