/* --------
   Elements
   -------- */

%paragraph {
  @include font(14, 3, $lato);
}

// Titles

%title-h1 {
  @include font(18, 4, $lato);
}

%title-h2 {
  @include font(18, 4, $latoBold);
}

%title-h3--lg {
  @include font(22, 5, $lato);
  color: $red;
}

%title-h3 {
  @include font(18, 4, $lato);
  color: $red;

  @media (min-width: $mq-md) {
    @include font-size(22, 4);
  }
}

%title-h5 {
  @include font(12, 3, $lato);
  color: $grey;
}

// Buttons

%btn {
  padding: 0;
  appearance: none;
  cursor: pointer;
}

.btn-primary {
  @extend %btn;
  @include font(14, 6, $lato);
  position: relative;
  height: 45px;
  line-height: 45px;
  min-width: 275px;
  padding: 0 20px;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: $white;
  background: $red;
  text-align: center;
}

.btn-primary--eat {
  padding-right: 25px;
  &:after {
    @include pseudoElement();
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    background: url("img/ico-ustensil.svg") center center no-repeat;
    background-size: contain;
  }
}

.btn-primary--map {
  padding-right: 25px;
  margin: 20px 0 20px 0;
  min-width: 80px;
  background-color: $greyLight2;
  color: $red;
  &:after {
    @include pseudoElement();
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background: url("img/ico-map.png") center center no-repeat;
    background-size: contain;
  }
}

.btn-primary--map.is-open {
  background-color: $red;
}

.btn-primary--list {
  padding-right: 25px;
  margin: 20px 0 20px 0;
  min-width: 80px;
  background-color: $red;
  &:after {
    @include pseudoElement();
    top: 15%;
    left: 33%;
    width: 30px;
    height: 30px;
    background: url("img/ico-list.png") center center no-repeat;
    background-size: contain;
  }
}

.btn-primary--list.is-open {
  background-color: $greyLight2;
}

// Form elements

input {
  @include font(18, 6, $latoLight);
  height: 45px;
  width: 100%;
  padding: 10px 20px;
  border: 1px solid $greyLight2;
  box-shadow: 0 2px 16px 0 $greyLight3;
  color: $greyDark;
  background: $white;

  &:focus {
    outline: 0;
  }
}

::placeholder {
  @include font(18, 6, $latoLight);
  color: $grey;
}

label {
  @include font(14, 6, $lato);
}
