/* -------------------------------------------------
   RESET (http://meyerweb.com/eric/tools/css/reset/)
   ------------------------------------------------- */

* {
  box-sizing: border-box;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; // HTML5 display-role reset for older browsers
}
li, fieldset {
  display: block;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
ol, ul {
  list-style: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* ----------
   FORM RESET
   ---------- */

/* Form reset partially copied from HTML5boilerplate,
 * github.com/nathansmith/formalize and www.sitepen.com
 */

input,
button,
select,
label {
  vertical-align: middle;
  font-family: inherit;
  font-size: inherit;
}

legend {
  white-space: normal;
}

textarea {
  min-height: 5em;
  vertical-align: top;
  font-family: inherit;
  font-size: inherit;
  resize: vertical;
}

/* clickable input types in iOS */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

input[type="search"] {
  -webkit-appearance: textfield;
}

/* if select styling bugs on WebKit */
/* select { -webkit-appearance: none; } */

/* 'x' appears on right of search input when text is entered. This removes it */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

/* Removes inner padding and border in FF3+ */
button::-moz-focus-inner,
input[type='button']::-moz-focus-inner,
input[type='reset']::-moz-focus-inner,
input[type='submit']::-moz-focus-inner {
  border: 0;
  padding: 0;
}


/* ------------------------------
   CUSTOM ADDITION TO MEYER RESET
   ------------------------------ */

a {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  &:focus {
    outline: 0;
  }
}

img, iframe {
  display: block;
  max-width: 100%;
  height: auto;
}

span,
small {
  display: inline-block;
}

strong {
  display: inline-block;
}

label,
picture {
  display: block;
}

button {
  padding: 0;
  border: 0;
  outline: 0;
  background: none;
}


// Solve a bug on Chrome browser

body {
  -webkit-animation-delay: 0.1s;
  -webkit-animation-name: fontfix;
  -webkit-animation-duration: 0.1s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -webkit-font-smoothing: antialiased;
}

@-webkit-keyframes fontfix {
  from { opacity: 1; }
  to   { opacity: 1; }
}
