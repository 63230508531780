/* -----------------------
   TABLE PRIMARY COMPONENT
   ----------------------- */

// Used for Destop version

.table-primary {
  width: 100%;
  table-layout: fixed;
}

.table-primary__th {
  width: 12%;
  border-right: 4px solid $greyLightest;
  border-radius: 8px 8px 0 0;
  text-align: center;
  background: $blueHaze;

  &:first-child {
    width: 100px;
    background: transparent;
  }

  &:last-child {
    border-right: 0;

    .table-primary__day {
      margin-left: 0;
    }
  }
}

.table-primary__day {
  @include font(15, 4, $lato);
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 7px 0;
  margin-left: -2px;
  transform: translateY(-50%);
  color: $white;
  text-transform: capitalize;
  border-radius: 50%;
  background: $greenTurquoise;
  text-align: center;
}
.table-primary__day.today{
  border: 2px solid #e83731;
}

.table-primary__tr:first-child td:not(:first-child) {
  width: calc((100% - 100px) / 7);
  padding: 10px 5px 8px 5px;
}

.table-primary__tr:first-child td:first-child {
  padding: 10px 10px 8px 5px;
}

.table-primary__tr:last-child td:not(:first-child) {
  width: calc((100% - 100px) / 7);
  padding: 8px 5px 20px 5px;
  border-radius: 0 0 8px 8px;
  text-align: center;
  background: $blueHaze;
}

.table-primary__tr:last-child td:first-child {
  padding: 8px 10px 20px 5px;
}

.table-primary__td {
  @include font(14, 4, $lato);
  height: 40px;
  border-right: 4px solid $greyLightest;
  vertical-align: middle;
  color: $greyDark;
  background: $blueHaze;
  text-align: center;

  &:first-child {
    width: 100px;
    background: transparent;
    text-align: right;
  }
}

.table-primary__td p{
 padding: 4px;
}