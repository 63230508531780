/* ---------------
   CRUSH COMPONENT
   --------------- */

.crush--spotlight {
  .crush__title {
    @extend %title-h3--lg;
  }

  .crush__link {
    width: 100%;
    @media (min-width: $mq-sm) {
      display: flex;
    }
  }

  .crush__img-wrapper {
    position: relative;
    margin-bottom: 20px;
    padding-bottom: 56.25%; /* 16/9 ratio */

    @media (min-width: $mq-sm) {
      flex: 62% 1 0;
      width: 62%;
      margin-bottom: 0;
    }
  }

  .crush__content {
    @media (min-width: $mq-sm) {
      display: block;
      flex: 38% 1 0;
      width: 38%;
      padding: 2px 16px;
    }
  }

  .crush__fake-link {
    font-size: 18px;
  }
}

.crush {
  position: relative;
  padding-bottom: 25px;
  border-bottom: 1px dashed $red;

  &:after {
    position: absolute;
    content: '';
    bottom: 4px;
    left: 0;
    width: 100%;
    border-bottom: 1px dashed $red;
  }
}

.crush--big-on-mobile {
  padding-bottom: 30px;

  @media (min-width: $mq-md) {
    padding-bottom: 25px;
  }

  .crush__title {
    @extend %title-h3--lg;
  }

  .crush__link {
    width: 100%;
  }

  .crush__img-wrapper {
    margin-bottom: 20px;
    padding-bottom: 56.25%; /* 16/9 ratio */
  }

  .crush__fake-link {
    font-size: 18px;
  }
}

.crush--small {
  .crush__title {
    @extend %title-h3;
    margin-bottom: 3px;
  }

  .crush__link {
    display: flex;

    @media (min-width: $mq-sm) {
      display: block;
    }
  }

  .crush__img-wrapper {
    flex: 54% 1 0;
    width: 54%;
    padding-bottom: 30.25%;

    @media (min-width: $mq-sm) {
      width: 100%;
      padding-bottom: 56.25%; /* 16/9 ratio */
      margin-bottom: 22px;
    }
  }

  .crush__content {
    flex: 46% 1 0;
    width: 46%;
    padding: 0 0 0 10px;

    @media (min-width: $mq-sm) {
      width: 100%;
      padding: 0;
    }
  }

  .crush__desc {
    display: none;

    @media (min-width: $mq-sm) {
      display: block;
    }
  }
}

.crush__img-wrapper {
  position: relative;
  overflow: hidden;
  height: 0;
}

.crush__img-copyrights {
  @include font(12, 2, $lato);
  position: absolute;
  bottom: 5px;
  left: 8px;
  right: 8px;
  color: $white;
  text-shadow: 0px 1px 0px rgb(0, 0, 0);

  @media (max-width: $mq-md) {
    @include font(8, 1, $lato);
    bottom: 2px;
    left: 4px;
    right: 2px;
  }
}

.crush__img {
  position: absolute;
  width: 100%;
  max-width: none;
}

.crush__content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: $mq-sm) {
    display: block;
  }
}

.crush__title {
  margin-bottom: 3px;
}

.crush__location {
  @include font(12, 6, $lato);
  margin-bottom: 5px;
  color: $grey;
}

.crush__desc {
  @include font(14, 6, $lato);
}

.crush__fake-link {
  @include font(16, 6, $latoBold);
  display: block;
  margin-top: 10px;
}
