/* ----------------
   CITIES COMPONENT
   ---------------- */

.cities {
  .city {
    margin-bottom: 10px;
  }

  ::placeholder {
    @include font(14, 3, $lato);
    color: $greenBay;
  }
}

.cities__inner {
  padding: 0 30px 25px 30px;
  border-bottom: 1px dashed $greyLight;
}

.cities__search {
  margin-bottom: 10px;
}

.cities__input {
  @include font(14, 3, $lato);
  border: 1px solid $greyLight3;
  box-shadow: none;
  border-radius: 2px;
  color: $greenBay;
}
