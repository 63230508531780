/* ---------
   HOME PAGE
   --------- */

.main--home {
  & > .sub-header {
    lost-center: 10/12 no-flex;
  }
  .annonce{
    margin: 15px 0 0 0;
    text-align: center;
    font-size: 2rem;
  }
  .annonce p{
    margin-bottom: 1.2rem;
    font-size: 1rem;
  }
  .annonce h2{
    font-size: 1.5rem;
    font-weight: bold;
  }
  .searchboxes {
    margin-bottom: 20px;

    @media (min-width: $mq-sm) {
      margin-bottom: 30px;
    }

    @media (min-width: $mq-md) {
      margin-bottom: 40px;
    }
  }

  .searchboxes__inner {
    lost-center: 260px;
    position: relative;

    @media (min-width: $mq-xs) {
      max-width: 400px;
    }

    @media (min-width: $mq-sm) {
      max-width: 635px;
    }

    .search-box {
      lost-column: 1/2 2 25px;

      @media (min-width: $mq-xs) {
        lost-column: 1/2 2 35px;
      }
    }
  }

  .tns {
    &.tns--filter {
      margin-bottom: 20px;

      @media (min-width: $mq-md) {
        margin-bottom: 35px;
      }
    }
  }

  .crushes {
    margin-bottom: 50px;
  }

  .crushes__inner {
    lost-center: 10/12 no-flex;
    padding-top: 25px;
    border-top: 1px dashed $greyLight;

    @media (min-width: $mq-md) {
      padding-top: 0;
      border-top: 0;
    }
  }

  .crushes__title {
    @extend %title-h2;
    margin-bottom: 28px;

    @media (min-width: $mq-md) {
      margin-bottom: 42px;
    }
  }

  .boxfilter {
    lost-center: 10/12 no-flex;
    margin-bottom: 30px;

    @media (min-width: $mq-lg) {
      max-width: 800px;
      margin-bottom: 50px;
    }

    .boxfilter__boxes {
      display: flex;
      flex-wrap: wrap;
    }

    .flat-box {
      lost-column: 1/2 2 12px;
      margin-bottom: 12px;

      @media (min-width: $mq-sm) {
        lost-column: 1/4 4 35px;
      }
      @media (min-width: $mq-md) {
        margin-bottom: 40px;
      }
    }

    .flat-box__body {
      justify-content: flex-start;
    }
  }

  .boxfilter__btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 0 35px 0;

    @media (min-width: $mq-md) {
      margin: 0 0 90px 0;
    }

    a:last-child {
      margin-left: 14px;
    }
  }

  .hopla {
    margin-bottom: 22px;

    @media (min-width: $mq-md) {
      margin-bottom: 42px;
    }
  }

  .hopla__inner {
    lost-center: 10/12 no-flex;

    @media (min-width: $mq-xs) {
      max-width: 400px;
    }

    @media (min-width: $mq-sm) {
      max-width: 468px;
    }
  }

  .logo__francebleu{
    width: 80px;
    margin:auto;
  }

  div.crushes.francebleu > div > a.lien_img{
    display: block;
    margin: 10px;
  }
}
