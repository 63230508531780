/* ----------
   ABOUT PAGE
   ---------- */

.main--about {
  .sub-header {
    lost-center: 10/12 no-flex;
    margin-bottom: 45px;

    @media (min-width: $mq-md) {
      margin-bottom: 55px;
    }
  }
  a.link {
    font-weight: 700;
    text-decoration: underline;
  }
  .generic-content {
    lost-center: 10/12 no-flex;
    margin-bottom: 30px;

    @media (min-width: $mq-md) {
      lost-center: 8/12 no-flex;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 55px;
    }
  }
  .about-img {
    margin: auto;
  }
}
