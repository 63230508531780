/* ----------------
   BANNER COMPONENT
   ---------------- */

.banner {
  border-radius: 3px;
  box-shadow: 0 2px 8px 0 $whiteDirty;
}

.banner__inner {
}

.banner__img {
  border-radius: 3px 3px 0 0;
}

.banner__figcaption {
  min-height: 45px;
  padding: 15px 10px;
  border-left: 1px solid $greyLight;
  border-right: 1px solid $greyLight;
  border-bottom: 1px solid $greyLight;
  background: $white;
}

.banner__txt {
  @include font(15, 3, $latoBold);
  color: $greyDark;
  text-align: center;
}

.banner__txt.nogeo{
  padding: 20px;
  @include font(15, 3, $lato);
  strong{
      @include font(15, 3, $latoBold)
  }
}
