/* -----------------------
   UPCOMING DAYS COMPONENT
   ----------------------- */

.upcoming-days {
  .upcoming-days__list {
    padding: 0 30px 25px 30px;
    border-bottom: 1px dashed $greyLight;
  }

  .day {
    lost-column: 1/4 4 6px;
    margin-bottom: 10px;
  }
}

/* -------------
   DAY COMPONENT
   ------------- */

.day {
  cursor: pointer;
  background-image: $gradientLight;

  &.is-active {
    background: $greenTurquoise;
  }

  &:hover {
    background: $greenTurquoise;
  }
}

.day__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90px;
  color: $white;
}

.day__number {
  @include font(20, 4, $lato);
  display: block;
  margin-bottom: 5px;
}

.day__month {
  @include font(10, 4, $lato);
  display: block;
  text-transform: capitalize;
}
