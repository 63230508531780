@import 'styles/base/base';
@import 'styles/components/components';
@import 'styles/pages/pages';

/* -------------
   GLOBAL LAYOUT
   ------------- */

html {
  font-size: 62.5%;
}

body {
  min-height: 100vh;
  font-family: $font-primary;
  line-height: $base-line-height;
  color: $greyDark;
  background: $white;
}

a {
  color: $greyDark;
  font-family: $lato, $sansSerif;
}

#root {
  min-height: 100vh;
}

// Sticky footer

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .main {
    flex: 1 0 auto;
  }
}
