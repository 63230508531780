/* ----------------
   HEADER COMPONENT
   ---------------- */

.header-wrapper {
  margin-bottom: 20px;

  @media (min-width: $mq-md) {
    margin-bottom: 52px;
  }
}

.header__wrapper img {
  margin: 0 auto;
}

.header {
  display: flex;
  align-items: center;
  height: 66px;
  background: $whitish;
  border-bottom: 2px solid $greyLight;
  width: 100%;
  // position: fixed;
  z-index: 10;

  &.sticky {
    position: fixed;
    top: 0;
  }
}

.header__inner {
  position: relative;
  width: 100%;
  padding: 0 30px;

  @media (min-width: $mq-md) {
    display: flex;
    padding: 0 36px;
  }
}

.header__container-logo {
  display: flex;

  @media (min-width: $mq-md) {
    flex: 1 0 170px;
    width: 170px;
    margin-right: 40px;
    align-items: center;
  }
}

.header__logo {
  display: flex;
  align-items: center;

  & img {
    width: 75%;
    padding: 20px;
    margin-left: 0;

    @media (min-width: 1024px) {
      width: 95%;
      padding: 0;
    }
  }
}

.header__logo-link {

  @media (min-width: $mq-md) {
    margin-right: 0;
  }
}

.header__btn-wrapper {
  padding-top: 5px;

  @media (min-width: $mq-md) {
    display: none;
  }
}

.header__btn {
  display: block;
  padding: 5px;
  width: 25px;
  height: 20px;
  background: no-repeat;
  background-size: contain;
  transform: rotate(-180deg);
  transition: transform 0.2s ease-in-out;

  &.is-open {
    transform: rotate(0deg);
  }
}

.header__img-alsace {
}

.header__main {
  display: none;
  position: absolute;
  top: calc(100% + 13px);
  left: 0;
  z-index: 1;
  width: 100%;
  padding: 0 30px 30px 30px;
  background: $white;

  @media (min-width: $mq-md) {
    display: flex;
    justify-content: space-between;
    position: static;
    padding: 0;
    background: transparent;
  }

  &.is-open {
    display: block;
    @media (min-width: $mq-md) {
      display: flex;
    }
  }
}

.header__nav {
  margin-bottom: 27px;
  @media (min-width: $mq-md) {
    margin-bottom: 0;
    width: 65%;
  }
}

.header__nav-list {
  @media (min-width: $mq-md) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.header__nav-list li:last-child a {
  display: inline-flex;
  padding: 11px 0 11px 11px;
  width: 160px;
  @media (max-width: $mq-sm) {
    width: unset;
  }
  background-color: #e83731;
  color: white;
  border-radius: 2px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  height: 65px;
}

.header__nav-list li:last-child > a > img {
  max-width: 14px;
  margin-right: 10px;
}
.header__nav-item {
  border-top: 1px solid $greyLight;

  @media (min-width: $mq-md) {
    border-top: 0;
    margin-right: 20px;
  }

  @media (min-width: 1250px) {
    margin-right: 40px;
  }

  &:last-child {
    border-bottom: 1px solid $greyLight;

    @media (min-width: $mq-md) {
      margin-right: 0;
      border-bottom: 0;
    }
  }
}

.header__nav-link {
  @include font(18, 6, $latoBold);
  width: 100%;
  padding: 20px 0;

  @media (min-width: $mq-md) {
    font-size: 16px;
  }

  &.is-active {
    @media (min-width: $mq-md) {
      border-bottom: 2px solid $greenTurquoise;
    }
  }
}

// select langues


.flag-select {
  padding-bottom: 15px;
}

.flag-select .flag-options, .flag-select__options {
  width: 110px;
  border-radius: 0px;
}
.flag-select .flag-option .country-label, .flag-select__option__label{
  padding: 4px 9px;
}
.flag-select img {
  float: left;
}
.flag-select span.arrow-down {
  display: none;
}
.flag-select__btn:after, .flag-select__btn[aria-expanded=true]:after{
  display: none;
}

.menu-flags-mobile > button {
  border: none;
}

.menu-flags-mobile > button::after {
  display: none !important;
}

.menu-flags-mobile > ul[role="listbox"] {
  width: 130px;
}

@media (min-width: $mq-md) {
  .menu-flags-mobile {
    display: none;
  }

  .menu-flags-desktop > button {
    border: none;
  }

  .menu-flags-desktop > button::after {
    display: none !important;
  }

  .menu-flags-desktop > ul[role="listbox"] {
    width: 130px;
  }
}

@media (max-width: $mq-md) {

  .header__logo-link img {
    max-width: 180px;
  }
  .menu-flags-desktop {
    display: none;
  }

  .menu-flags-desktop > ul {
    width: 130px;
  }
}

.header__social {
  margin-bottom: 15px;
  align-items: center;

  @media (min-width: $mq-md) {
    display: flex;
    margin-bottom: 0;
    width: 38%;
  }

  @media (min-width: 1250px ){
    margin-left: 25px;
  }
}

.header__social-txt {
  @include font(14, 6, $lato);
  margin-bottom: 15px;
  color: $grey;
  text-align: center;

  @media (min-width: $mq-md) {
    margin-bottom: 0;
    margin-right: 15px;
  }
}

.header__social-list {
  display: flex;
  justify-content: center;
}

.header__social-item {
  margin-right: 20px;

  @media (min-width: $mq-md) {
    margin-right: 15px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.header__social-link {
  display: block;
  width: 40px;
  height: 40px;
  text-indent: -9999px;
  background-size: contain;

  @media (min-width: $mq-md) {
    width: 30px;
    height: 30px;
  }
}

.header__img-wrapper.header__img-wrapper--mobile {
  margin-left: -31px;
  margin-right: -31px;
}
.header__img-wrapper {
  margin-top: 60px;
}
.header__img {
  width: 100%;
}
