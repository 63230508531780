/* -----------------
   LIBRARY OF MIXINS
   ----------------- */

// Base for background on pseudo-element

@mixin pseudoElement() {
  content: '';
  position: absolute;
  display: block;
}

// Shorthand for absolute elements that take full width and full height

@mixin absoluteFullSpace() {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

// Clearfix

@mixin clearfix() {
  &:before,
  &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

// Remove Clearfix

@mixin clearNone() {
  &:before,
  &:after {
    display: none;
  }
  &:after {
    clear: none;
  }
}

// Ceil on Hundredths

@function ceilHundredths($numbers) {
  $numbers: $numbers * 100;
  @if ($numbers < 1) {
    $numbers: $numbers - 1;
  } @else {
    $numbers: $numbers + 1;
  }
  @return round($numbers) / 100;
}

// px to em/rem

@mixin font-size($sizeValue: 16, $lh: 6) {
  font-size: ($sizeValue) + px; // Fallback for older browsers
  font-size: ($sizeValue / 10) + rem;
  $l-height: $sizeValue + $lh;
  line-height: ceilHundredths($l-height / $sizeValue);
}

// px to em/rem and font-family

@mixin font($sizeValue: 16, $lh: 6, $fontfamily: $lato) {
  $font-size: ($sizeValue / 10) + rem;
  $line-height: ceilHundredths(($sizeValue + $lh) / $sizeValue);

  @if ($fontfamily == $latoLight) {
    font: normal 300 $font-size/$line-height $lato, $sansSerif;
  } @else if ($fontfamily == $latoBold) {
    font: normal 700 $font-size/$line-height $lato, $sansSerif;
  } @else {
    font: normal 400 $font-size/$line-height $lato, $sansSerif;
  }
}

// Style placeholder text (Not supported by autoprefixer yet)

@mixin placeholder-shown {
  &::-webkit-input-placeholder {
    text-overflow: ellipsis;
    padding-right: 2px;
    @content;
  }
  &::-moz-placeholder {
    // Firefox sets a partial opacity on placeholder text, if you change the color
    // (e.g. to a shade of gray) you need to set the opacity back to 100%.
    opacity: 1;
    text-overflow: ellipsis;
    padding-right: 2px;
    @content;
  }
  &:-ms-input-placeholder {
    text-overflow: ellipsis;
    padding-right: 2px;
    @content;
  }
  // Trying to make it more future-proof, but only support in late 2015 is in WebKit nightlies.
  &:placeholder-shown {
    text-overflow: ellipsis;
    padding-right: 2px;
    @content;
  }
}

// Mixin background gradient

@mixin gradient($start-color, $end-color, $orientation) {
  @if $orientation == vertical {
    // Vertical
    background: linear-gradient(to bottom, $start-color 0%, $end-color 100%);
  } @else if $orientation == horizontal {
    // Horizontal
    background: linear-gradient(to right, $start-color 0%, $end-color 100%);
  } @else if $orientation == diagonal {
    // Diagonal
    background: linear-gradient(
      to bottom right,
      $start-color 0%,
      $end-color 100%
    );
  } @else {
    // Radial
    background: radial-gradient(
      ellipse at center,
      $start-color 0%,
      $end-color 100%
    );
  }
}
