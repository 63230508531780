/* ----------------
   FOOTER COMPONENT
   ---------------- */

.footer {
  position: relative;
  padding: 20px 0 125px 0;
  border-top: 1px dashed $greyLight;
}

// Footer primary

.footer__tagline {
  margin-bottom: 15px;
  text-align: center;
}

.footer__tagline-top {
  @include font(11, 6, $latoBold);
  width: 100%;
  color: $red;
}

.footer__tagline-bottom {
  @include font(11, 6, $lato);
  display: flex;
  justify-content: center;
  width: 100%;
  color: $greenTurquoise;
}

.footer-img {
 padding-bottom: 20px;
}
.footer__tagline-img {
  width: 65px;
  margin: -4px 6px 0 10px;
}

.footer__tagline-img-bis {
  width: 65px;
  height: 48px;
  margin: auto;
}

.footer__bottom {
  @include font(10, 6, $latoBold);
  color: $grey;
  text-align: center;
  img {
    margin: auto;
  }
}

.footer__inner .links {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  margin: 0 37px;
  @media (max-width: $mq-xs) {
    flex-direction: column;
    align-items: center;
  }
}

.footer__inner .links a {
  width: fit-content;
  padding: 4px;
}

// Footer secondary

.footer.secondary {
  padding: 20px 0 20px 0;
}
.experts {
  padding-bottom: 20px;
  text-align: center;
}

.experts__inner {
  lost-center: 10/12 no-flex;

  @media (min-width: $mq-md) {
    lost-center: 6/12 no-flex;
    padding-left: 50px;
    padding-right: 50px;
  }
}

.experts__title {
  @include font(22, 5, $lato);
  margin-bottom: 25px;
  color: $blue;
}

.experts__contact {
  padding: 0 30px;
  margin-bottom: 30px;
}

.experts__contact-link {
  @include font(14, 3, $latoBold);
  lost-column: 1/2;
  color: $blue;
  white-space: nowrap;
}

.experts__contact-link:nth-child(2n),
.experts__contact-link:nth-child(1n) {
  @media (max-width: $mq-xs) {
    display: block;
    margin: 0px auto 10px 20px;
  }
}

.experts__contact-link {
  &:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 10px;
  }

  &.experts__contact-link--tel {
    &:before {
      background: url("img/ico-tel.svg") $blue center center no-repeat;
    }
  }

  &.experts__contact-link--email {
    &:before {
      background: url("img/ico-mail.svg") $blue center center no-repeat;
    }
  }

  span {
    position: relative;

    @media (min-width: $mq-xs) {
      top: -12px;
    }
  }
}

.experts__profiles {
  lost-flex-container: row;
}

.profile {
  lost-column: 1/3;
}

.profile_figure {
  margin-bottom: 8px;
}

.profile__img {
  display: inline-block;
  border-radius: 50%;
}

.profile__figcaption {
  @include font(12, 3, $lato);
  color: $grey;
}

.footer__logo-wrapper {
  padding-top: 25px;
  text-align: center;
}

.footer__logo-territory {
  display: inline-block;
  max-height: 80px;
  max-width: 200px;
}

.footer__btn-message {
  position: fixed;
  z-index: 1;
  right: 110px;
  bottom: 45px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12), 0 8px 8px 0 rgba(0, 0, 0, 0.24);
  background: $red center center
    no-repeat;

  @media (min-width: $mq-md) {
    right: 110px;
    bottom: 45px;
  }
  @media (max-width: $mq-sm) {
    right: 15px;
    bottom: 25px;
  }
}

footer a {
  transition: all 0.25s ease-in-out;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.footer__btn-message-text {
  opacity: 0;
  position: fixed;
  z-index: 0;
  right: 0;
  bottom: 45px;
  width: 140px;
  height: 60px;
  padding: 20px 0 0 35px;
  color: black;
  font-size: 14px;
  background: white;

  @media (min-width: $mq-md) {
    right: 45px;
    bottom: 45px;
  }
  @media (max-width: $mq-sm) {
    display: none;
  }
}
.footer__btn-message:hover + .footer__btn-message-text,
.itemHover {
  opacity: 1;
  transform: translate(50px);
}

.footer__btn-message:hover + .footer__btn-message-text,
.footer__btn-message-text.itemHover {
  background: $greyLight;
}

.cookieConsent {
  font-size: 1.5rem;
}
