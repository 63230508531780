/* ---------------------
   CHECKED BTN COMPONENT
   --------------------- */

.checked-btn {
  position: relative;
  display: block;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
  border-radius: 50%;
  background: $whiteDirty;
  text-indent: -9999px;

  @media (min-width: $mq-xs) {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
  }

  @media (min-width: $mq-md) {
    width: 38px;
    height: 38px;
    flex: 0 0 38px;
  }

  &:before {
    @include pseudoElement();
    top: 50%;
    left: 50%;
    width: 9px;
    height: 7px;
    transform: translate(-50%, -50%);
    background: url('img/ico-check-grey.svg') center center no-repeat;
    background-size: contain;

    @media (min-width: $mq-xs) {
      width: 11px;
      height: 9px;
    }

    @media (min-width: $mq-md) {
      width: 14px;
      height: 11px;
    }
  }

  &.is-active {
    background: $greenTurquoise;
    &:before {
      background: url('img/ico-check-white.svg') center center no-repeat;
      background-size: contain;
    }
  }
}
