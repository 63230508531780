/* ---------------------
   TINY SLIDER COMPONENT
   --------------------- */

.tns--filter {
  .tns-ovh {
    padding-bottom: 10px !important; // To show the box shadows
  }
}

.tns--static {
  .tns-ovh {
    position: relative;
    padding-bottom: 42px !important; // To show the box shadows & the bullets

    @media (min-width: $mq-md) {
      padding-bottom: 55px !important; // To show the box shadows & the bullets
    }
  }
  .tns-nav {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      width: 9px;
      height: 9px;
      border-radius: 50%;
      margin-left: 11px;
      opacity: 0.3;
      background: $red;

      &.tns-nav-active {
        opacity: 1;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.tns-outer {
  position: relative;
}

.tns-controls {
  display: flex;
  position: absolute;
  z-index: 1;
  top: 50%;
  width: 100%;
  margin-top: -50px;
  outline: 0;
  justify-content: space-between;
  @media (max-width: $mq-md) {
    margin-top: 10px;
  }
  button {
    position: relative;
    z-index: 12;
    height: 42px;
    width: 24px;
    color: $greyDark;
    font-size: 50px;
    background: $red;
    text-indent: -9999px;

    @media (min-width: $mq-md) {
      height: 56px;
      width: 32px;
    }

    &:after {
      position: absolute;
      content: '';
      display: block;
      width: 9px;
      height: 12px;
      top: 50%;
      left: 50%;
      margin-top: -6px;
      margin-left: -3px;
      background: url('img/ico-arrow-slider.svg') center center no-repeat;
      background-size: contain;
    }
  }

  button[data-controls='prev'] {
    border-radius: 0 10px 10px 0;

    @media (min-width: $mq-md) {
      border-radius: 0 16px 16px 0;
    }

    &:after {
      margin-left: -6px;
    }
  }

  button[data-controls='next'] {
    border-radius: 10px 0 0 10px;

    @media (min-width: $mq-md) {
      border-radius: 16px 0 0 16px;
    }

    &:after {
      transform: rotate(-180deg);
    }
  }
}
