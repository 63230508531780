/* --------------------
   SEARCH-BOX COMPONENT
   -------------------- */

.search-box {
  box-shadow: 0 2px 8px 0 $whiteDirty;

  &.is-active {
    box-shadow: none;

    .search-box__body {
      border-color: transparent;
      color: $white;
      background: $greenTurquoise;
    }

    .search-box__header {
      border-color: transparent;
      background-image: $gradientLight;
    }

    .search-box__inner.search-box__inner--date,
    .search-box__inner.search-box__inner--location {
      .search-box__header {
        @media (max-width: $mq-md) {
          background: $greenTurquoise;
        }
      }
    }
  }
}

.search-box__inner.search-box__inner--date,
.search-box__inner.search-box__inner--location {
  .search-box__header {
    border-radius: 4px;
  }
}

.search-box__inner.search-box__inner--eat {
  .search-box__header {
    border-radius: 4px 4px 0 0;
  }
}

.search-box__link {
  display: block;
  width: 100%;
}

.search-box__header {
  @include gradient(#656d78, #2f3640, 'horizontal');
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  padding: 8px 35px 8px 8px;
  border: 1px solid $greyMedium;

  @media (min-width: $mq-md) {
    padding: 8px 16px;
  }

  &:after {
    @include pseudoElement();
    right: 10px;
    width: 24px;
    height: 24px;
    top: 50%;
    transform: translateY(-50%);

    @media (min-width: $mq-md) {
      right: 16px;
    }
  }

  &.search-box__header--date {
    &:after {
      background: url('img/ico-calendar.svg') center center no-repeat;
    }
  }

  &.search-box__header--location {
    &:after {
      background: url('img/ico-location.svg') center center no-repeat;
    }
  }
}

.search-box__title {
  @include font(13, 6, $latoBold);
  width: 100%;
  color: $white;

  @media (min-width: $mq-md) {
    font-size: 18px;
  }
}

.search-box__body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  padding: 8px;
  border-right: 1px solid $greyLight;
  border-bottom: 1px solid $greyLight;
  border-left: 1px solid $greyLight;
  border-radius: 0 0 4px 4px;
  background: $white;

  &.hide-mobile {
    @media (min-width: $mq-md) {
      display: flex;
    }
  }
}

.search-box__txt {
  @include font(12, 6, $latoBold);
  text-align: center;
  text-transform: capitalize;

  @media (min-width: $mq-md) {
    font-size: 15px;
  }
}
