/* ---------------
   FLAT-BOX COMPONENT
   --------------- */

.flat-box {
  .checked-btn {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
  }
}

.flat-box__inner {
  border: 1px solid $greyLight;
  border-radius: 3px;
  box-shadow: $box-shadow;
}

.flat-box__content {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 10px 0 15px;
  justify-content: space-between;
  align-items: center;
  height: 48px;

  @media (min-width: $mq-md) {
    padding: 0 16px;
  }
}

.flat-box__title {
  @include font(12, 3, $latoBold);

  @media (min-width: $mq-sm) {
    @include font-size(15, 3);
  }

  @media (min-width: $mq-md) {
    @include font-size(12, 3);
  }
}

.flat-box .checked-btn.is-active{
  background-color: $red;
}