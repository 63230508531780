/* -------------------------
   TABLE SECONDARY COMPONENT
   ------------------------- */

// Used for Mobile version

.table-secondary {
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (min-width: $mq-xs) {
    padding-left: 53px;
    padding-right: 53px;
  }
}

.table-secondary__days {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.table-secondary__list-wrapper {
  display: flex;
  flex: 0 0 calc(100% - 17px);
  width: calc(100% - 17px);
  margin-left: -17px;
}

.table-secondary__day {
  display: flex;
  height: 58px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.table-secondary__day__inner {
  @include font(15, 4, $lato);
  display: inline-block;
  width: 35px;
  height: 35px;
  padding: 7px 0;
  color: $white;
  text-transform: capitalize;
  border-radius: 50%;
  background: $greenTurquoise;
  text-align: center;

  &.table-secondary__day__inner--spacing {
    background: transparent;
  }
}

.table-secondary__day__inner.today{
  border: 2px solid #e83731;
}

.table-secondary__list-am {
  flex: 0 0 calc(50% - 4px);
  width: calc(50% - 4px);
  margin-right: 8px;
}

.table-secondary__list-pm {
  flex: 0 0 calc(50% - 4px);
  width: calc(50% - 4px);
}

.table-secondary__list-permanent {
  width: 100%;
}

.table-secondary__item {
  @include font(13, 4, $lato);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 58px;
  margin-bottom: 8px;
  border-radius: 8px;
  color: $greyDark;
  background: $blueHaze;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }

  &.table-secondary__item--head {
    align-items: flex-end;
    background: transparent;
  }
}

.table-secondary__item p { padding:4px;}