/* ---------------
   SLIDE COMPONENT
   --------------- */

.slide {
  &.slide--static {
    .slide__content {
      justify-content: center;
      text-align: center;
    }
  }
}

.slide__responsive-wrapper {
  position: relative;
  overflow: hidden;
  height: 0;
  padding-top: 53.25% !important;

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.slide__img {
  width: 100%;
  border-radius: 3px 3px 0 0;
}

.slide__credit {
  position: absolute;
  @include font(12, 2, $lato);
  bottom: 5px;
  left: 8px;
  right: 8px;
  color: $white;
  text-shadow: 0px 1px 0px rgb(0, 0, 0);
  @media (max-width: $mq-md) {
    @include font(8, 1, $lato);
    bottom: 2px;
    left: 4px;
    right: 2px;
  }
}

.tns-item .slide__credit {
  position: relative;
  text-align: left;
  height: 10px;
  bottom: 20px;
  @media (max-width: $mq-md) {
    @include font(7, 1, $lato);
    bottom: 12px;
  }
}

.slide__inner {
  border: 1px solid $greyLight;
  border-radius: 3px;
  box-shadow: $box-shadow;
  max-height: 450px;
  max-width: 800px;
  overflow: hidden;
  margin: auto;
}

.boxfilter__boxes .slide__inner {
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.slide__content {
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  height: 45px;

  @media (min-width: $mq-sm) {
    height: 55px;
  }

  @media (min-width: $mq-md) {
    height: 65px;
    padding: 0 16px;
  }
}

.slide__title {
  @include font(11, 3, $latoBold);

  @media (min-width: $mq-sm) {
    @include font-size(13, 3);
  }

  @media (min-width: $mq-md) {
    @include font-size(17, 3);
  }
}

.slide {
  .checked-btn {
    position: absolute;
    top: 0;
    right: 8px;
    transform: translateY(-50%);

    @media (min-width: $mq-xs) {
      right: 15px;
    }

    @media (min-width: $mq-md) {
      position: relative;
      transform: none;
      right: auto;
    }
  }
}
