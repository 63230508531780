/* -------------------
   SCHEDULES COMPONENT
   ------------------- */

.heading-primary {
  display: flex;
  justify-content: space-between;

  @media (min-width: $mq-md) {
    margin-bottom: 50px;
  }
}

.heading-primary__content {
  width: 100%;
  padding: 6px 10px;
  background: $blueHaze;
}

.heading-primary__txt {
  @include font(16, 4, $lato);
  color: $greyDark;
  text-align: center;

  @media (min-width: $mq-md) {
    @include font-size(18, 4);
  }
}

.heading-primary__btn {
  position: relative;
  display: block;
  flex: 0 0 35px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: $red;
  opacity: 0;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }

  &:after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -4px;
    width: 7px;
    height: 10px;
    background: url('img/ico-arrow-slider.svg') center center no-repeat;
    background-size: contain;
  }

  &.heading-primary__btn--prev {
    margin-right: 18px;
  }

  &.heading-primary__btn--next {
    margin-left: 18px;

    &:after {
      margin-left: -3px;
      transform: rotate(180deg);
    }
  }
}
