/* ------------------
   CONFIGS: VARIABLES
   ------------------ */

// font sizes

$base-font-size: 16px;
$base-line-height: 1.3;

// font stacks

$sansSerif: sans-serif;
$lato: 'Lato';
$latoLight: 'Lato Light';
$latoBold: 'Lato Bold';
$font-primary: $lato, $sansSerif;

// Colors

$black: #000;
$blackish: #2c3135;
$greyDark: #474747;
$greyMedium: #979797;
$greyMedium2: #dddddd;
$grey: #6b6b6b;
$greyLight: #e5e5e5;
$greyLight2: #dbdbdb;
$greyLight3: #ececec;
$greyLightest: #f8f8f8;
$whiteDirty: #efefef;
$whitish: #fcfcfc;
$white: #fff;

$greenTurquoise: #27d9cb;
$greenBay: #4d9d97;
$red: #e83731;
$blue: #15a4c5;
$blueHaze: #edf2f5;

$gradientLight: linear-gradient(-135deg, #d7dbe1 0%, #b5c1d1 100%);

// Breakpoints

$mq-xxs: 320px;
$mq-xs: 480px;
$mq-sm: 768px;
$mq-md: 1024px;
$mq-lg: 1200px;
$mq-xl: 1600px;

// Transition

$box-shadow: 0 2px 8px 0 $whiteDirty;
$bounceTransition: cubic-bezier(0.25, 0.25, 0.115, 1.445);
