/* --------------
   CITY COMPONENT
   -------------- */

.city {
  border: 1px solid $greyLight3;
  border-radius: 2px;
  cursor: pointer;

  &.is-active {
    background: $greyMedium2;
  }
}

.city__inner {
  display: flex;
  align-items: center;
  height: 45px;
  width: 100%;
  padding: 2px 5px;
}

.city__distance {
  position: relative;
  display: block;
  width: 40px;
  padding-top: 22px;
  margin-right: 10px;
  text-align: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 15px;
    height: 20px;
    background: url('img/ico-location-logo.svg') center center no-repeat;
    background-size: contain;
  }
}

.city__txt {
  @include font(14, 4, $lato);
  display: block;
  flex: 1 0 auto;
  font-size: 14px;
  color: $grey;
  text-transform: capitalize;
}
