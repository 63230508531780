/* ---------------
   MODAL COMPONENT
   --------------- */

.modal {
  &.modal--left {
    .modal__outer {
      @media (min-width: $mq-md) {
        left: -50px;
      }
    }
  }

  &.modal--right {
    .modal__outer {
      @media (min-width: $mq-md) {
        left: initial;
        right: -50px;
      }
    }
  }

  .modal__outer {
    @media (max-width: $mq-sm) {
      margin-top: 65px;
    }
    position: fixed;
    z-index: 1020;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 20px 30px 50px 30px;
    overflow: auto;
    background: $white;

    @media (min-width: $mq-md) {
      position: absolute;
      top: calc(100% + 14px);
      right: initial;
      bottom: initial;
      width: 400px;
      padding: 50px 42px 30px 42px;
      border: 1px solid $greyLight;
      box-shadow: 0 2px 8px 0 rgba($grey, 0.4);
    }
  }

  .modal__close,
  .modal__logo-wrapper,
  .searchboxes,
  .sub-header {
    @media (min-width: $mq-md) {
      display: none;
    }
  }
}

.modal__inner {
  position: relative;
}

.modal__close {
  display: block;
  position: absolute;
  z-index: 1020;
  top: 20px;
  left: 30px;
  padding: 2px;
  width: 32px;
  height: 32px;
  background: center center no-repeat;
  cursor: pointer;
}

.modal__logo-wrapper {
  display: flex;
  justify-content: center;
}

.modal__overlay {
  position: fixed;
  z-index: 1019;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba($black, 0.8);
}

.modal {
  .searchboxes {
    margin-bottom: 20px;

    @media (min-width: $mq-sm) {
      margin-bottom: 30px;
    }

    @media (min-width: $mq-md) {
      margin-bottom: 40px;
    }
  }

  .searchboxes__inner {
    lost-center: 260px;

    @media (min-width: $mq-xs) {
      max-width: 400px;
    }

    @media (min-width: $mq-sm) {
      max-width: 635px;
    }

    .search-box {
      lost-column: 1/2 2 25px;

      @media (min-width: $mq-xs) {
        lost-column: 1/2 2 35px;
      }
    }
  }

  .modal__btn-wrapper {
    display: flex;
    justify-content: center;
  }

  .btn-primary {
    min-width: 260px;

    @media (min-width: $mq-xs) {
      min-width: 300px;
    }
  }

  // Modal date

  .current-date {
    max-width: 260px;
    margin: 0 auto 20px auto;

    @media (min-width: $mq-xs) {
      max-width: 400px;
    }

    @media (min-width: $mq-sm) {
      max-width: 635px;
      margin-bottom: 30px;
    }
  }

  .upcoming-days {
    margin-bottom: 30px;

    .upcoming-days__list {
      lost-center: 320px;

      @media (min-width: $mq-xs) {
        max-width: 360px;
      }
    }
  }

  // Modal location

  .modal__selection {
    margin: 55px 0 30px 0;
    text-align: center;

    @media (min-width: $mq-md) {
      margin-top: 0;
    }

    .modal__selection__btn {
      display: inline-block;
      width: 15px;
      height: 15px;
      background: center center no-repeat;
      background-size: contain;
    }

    .modal__selection__txt {
      @include font(12, 6, $lato);
      margin-top: 5px;
      color: $red;
    }
  }

  .cities__inner.custom__position{
    margin: 15px 0 15px 0
  }
  .cities {
    margin-bottom: 30px;
    
    .cities__inner {
      lost-center: 320px no-flex;

      @media (min-width: $mq-xs) {
        max-width: 400px;
      }

      @media (min-width: $mq-sm) {
        overflow: auto;
        max-height: 395px;
        max-width: 635px;
      }
    }
  }
}

// Modal abonnement (Register)

.modalSubscribe > .main > .video {
  min-height: 200px !important;
}
.modalSubscribe .sub-header {
  display: block;
}

.modalSubscribe #iframe__video {
  @media (max-width: $mq-sm) {
    display: none !important;
  }
}
.modalSubscribe .main--abonnement .form__inner {
  max-width: 300px;
  @media (max-width: $mq-sm) {
    max-width: 260px;
  }
}
.modal {
  &.modal--center {
    .modal__outer {
      @media (min-width: $mq-xs) {
        position: fixed;
        top: 5%;
        bottom: 5%;
        width: 60%;
        left: 20%;
        max-height: calc(100vh - 110px);
        overflow-y: auto;
      }
    }
  }
}
// Modal articles

.modal__image-container {
  position: absolute;
  left: 45%;
  top: 45%;
  transform: translate(-40%, -40%);
  width: 75%;
  height: 80%;
  overflow: visible;
  @media (max-width: $mq-md) {
    max-width: 90%;
    max-height: 90%;
    top: 30%;
    left: 10%;
    transform: translate(0);
    width: 100%;
    height: 100%;
  }
}
.modal__image {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  @media (max-width: $mq-md) {
    max-width: 90%;
    max-height: 90%;
    margin-top: 0%;
    margin-left: 0%;
  }
}

.modal__image-container p.credit {
  color: white;
  max-width: 90%;
  font-size: 13px;
  padding: 4px;
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
