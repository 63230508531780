/* ------------
   ARTICLE PAGE
   ------------ */

.main--article {
  & > .sub-header {
    lost-center: 10/12 no-flex;
    margin-bottom: 45px;

    @media (min-width: $mq-md) {
      margin-bottom: 55px;
    }
  }

  .searchboxes {
    margin-bottom: 20px;

    @media (min-width: $mq-sm) {
      margin-bottom: 30px;
    }

    @media (min-width: $mq-md) {
      margin-bottom: 40px;
    }
  }

  .searchboxes__inner {
    lost-center: 260px;
    position: relative;

    @media (min-width: $mq-xs) {
      max-width: 400px;
    }

    @media (min-width: $mq-sm) {
      max-width: 635px;
    }

    .search-box {
      lost-column: 1/2 2 25px;

      @media (min-width: $mq-xs) {
        lost-column: 1/2 2 35px;
      }
    }
  }

  // Slider

  .tns {
    &.tns--filter {
      margin-bottom: 20px;

      @media (min-width: $mq-md) {
        margin-bottom: 60px;
      }
    }

    &.tns--static {
      margin-bottom: 30px;

      @media (min-width: $mq-md) {
        margin-bottom: 45px;
      }
    }
  }

  // Article text content

  .generic-content {
    lost-center: 10/12 no-flex;
    margin-bottom: 40px;

    @media (min-width: $mq-md) {
      lost-center: 8/12 no-flex;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 70px;
    }
  }
  .generic-content img.flag {
    width: 24px;
    height: 24px;
    margin: 0 10px -5px 0;
    display: inline-block;
  }
  // Details

  .details__inner {
    lost-center: 275px no-flex;

    @media (min-width: $mq-sm) {
      max-width: 550px;
    }
  }

  // Buttons

  .btn-wrapper {
    display: none;

    @media (min-width: $mq-sm) {
      display: flex;
      justify-content: center;
      margin: 0 0 25px 0;
    }

    @media (min-width: $mq-md) {
      margin: 0 0 55px 0;
    }
  }

  .button-group {
    padding: 25px 0;

    @media (min-width: $mq-sm) {
      padding: 0 0 62px 0;
      background: $greyLightest;
    }
  }

  .btn-group__inner {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: $mq-sm) {
      flex-direction: row;
      justify-content: center;
    }

    a {
      max-width: 276px;

      &:first-child {
        @media (min-width: $mq-sm) {
          margin-right: 14px;
        }
        @media (max-width: $mq-sm) {
          margin-bottom: 22px;
        }
      }
    }
  }

  // Crushes

  .crushes {
    padding: 20px 0 25px 0;

    @media (min-width: $mq-sm) {
      padding: 40px 0;
    }

    @media (min-width: $mq-md) {
      padding: 60px 0 20px 0;
    }
  }

  .crushes__inner {
    lost-center: 10/12 no-flex;

    @media (min-width: $mq-md) {
      lost-center: 7/12 no-flex;
    }
  }

  .crushes__title {
    @extend %title-h3--lg;
    margin-bottom: 26px;

    @media (min-width: $mq-md) {
      margin-bottom: 42px;
    }
  }

  // Schedules
  #iframe__horaires {
    height: 550px;
  }
  .schedules {
    padding: 40px 0;
    background: $greyLightest;

    @media (min-width: $mq-md) {
      padding: 60px 0;
    }
  }

  .schedules__inner {
    lost-center: 10/12 no-flex;

    @media (min-width: $mq-md) {
      lost-center: 7/12 no-flex;
    }
    @media (max-width: $mq-xs) {
      max-height: 320px;
    }
  }

  .schedules__title {
    @extend %title-h3--lg;
    margin-bottom: 25px;
    text-align: center;
  }

  // Price

  .price {
    padding: 40px 0;
    background: $white;

    @media (min-width: $mq-md) {
      padding: 60px 0;
    }
  }

  .price__inner {
    lost-center: 275px no-flex;

    @media (min-width: $mq-sm) {
      max-width: 360px;
    }
  }

  .price__title {
    @extend %title-h3--lg;
    margin-bottom: 25px;
    text-align: center;
  }

  .price__item {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-between;
    background-image: linear-gradient(to right, $red 25%, $white 0%);
    background-position: center 75%;
    background-size: 4px 1px;
    background-repeat: repeat-x;
  }

  .price__category,
  .price__value {
    @include font(14, 4, $lato);
    display: block;
    min-width: 45px;
    flex: 0 0 auto;
    text-align: center;
    color: $greyDark;
    background: $white;
  }

  .price__category {
    padding: 0 18px 0 0;
  }

  .price__value {
    padding: 0 0 0 16px;
    text-align: right;
  }

  .boxfilter {
    lost-center: 10/12 no-flex;
    margin-bottom: 30px;

    @media (min-width: $mq-lg) {
      max-width: 800px;
      margin-bottom: 50px;
    }

    .boxfilter__boxes {
      display: flex;
      flex-wrap: wrap;
    }

    .flat-box {
      lost-column: 1/2 2 12px;
      margin-bottom: 12px;

      @media (min-width: $mq-sm) {
        lost-column: 1/4 4 35px;
      }
      @media (min-width: $mq-md) {
        margin-bottom: 40px;
      }
    }

    .flat-box__body {
      justify-content: flex-start;
    }
  }

  .boxfilter__btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 0 0 35px 0;

    @media (min-width: $mq-md) {
      margin: 0 0 90px 0;
    }

    a:last-child {
      margin-left: 14px;
    }
  }
  .hopla {
    margin-bottom: 22px;

    @media (min-width: $mq-md) {
      margin-bottom: 42px;
    }
  }

  .hopla__inner {
    lost-center: 10/12 no-flex;

    @media (min-width: $mq-xs) {
      max-width: 400px;
    }

    @media (min-width: $mq-sm) {
      max-width: 468px;
    }
  }
  img.pass-alsace {
    width: 130px;
    margin: 20px;
  }

  .information__complementaire {
    @include font(14, 3, $lato);
    .reservation__obligatoire {
      @include font(15, 3, $latoBold);
      margin: 10px 0 10px 0;
    }
  }

  ul.list {
    @include font(14, 3, $lato);
    background-color: $greyLightest;
    padding: 10px;
    margin: 10px 0 10px 0;
  }
  ul.list li {
    padding: 4px;
  }
  
  ul.list.circuit {
    margin: 0;
  }

  ul.list p {
    @include font(14, 3, $latoBold);
  }

  div.leaflet-container{
    @media (min-width: $mq-sm) {
      height: 450px;
    }
  }
}

.bold{
  font-weight: bolder!important;
}
