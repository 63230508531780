/* ----------------
   LOADER COMPONENT
   ---------------- */

.loader {
  display: flex;
  justify-content: center;
  position: relative;
  margin: 30px auto;
  text-align: center;

  &.is-spinning {
    .loader__ico {
      animation: spin 1s linear 0s infinite normal;
    }
  }

  &.loader--btn {
    cursor: pointer;
  }
}

.loader__inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loader__ico {
  display: inline-block;
  width: 16px;
  height: 16px;
  margin-bottom: 15px;
  background: url('img/ico-refresh.svg') center center no-repeat;
  background-size: contain;
}

.loader__txt {
  @include font(12, 4, $lato);
  color: $grey;
}
