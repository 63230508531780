/* -------------------------
   GENERIC CONTENT COMPONENT
   ------------------------- */

.generic-content {
  .generic-content__block {
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: 1px dashed $greyLight;

    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }

    & > div,
    p {
      @extend %paragraph;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .generic-content__heading {
    margin-bottom: 30px;
  }

  h3 {
    @extend %title-h3--lg;
    margin-bottom: 8px;

    &.ico-family {
      position: relative;
      padding-left: 35px;

      @media (min-width: $mq-sm) {
        padding-left: 0;
      }

      &:before {
        position: absolute;
        content: '';
        display: block;
        top: -5px;
        left: 0;
        width: 30px;
        height: 30px;
        background: url('img/ico-family-friendly.png') center center no-repeat;
        background-size: contain;

        @media (min-width: $mq-sm) {
          left: -38px;
        }
      }
    }
  }
}
