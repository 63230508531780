/** Carte **/

@import "~leaflet/dist/leaflet.css";
@import "~react-leaflet-markercluster/dist/styles.min.css";

#map-results.leaflet-container {
  height: 500px;
  width: 100%;
  margin: 20px 0 20px 0;
}

#map-results.leaflet-container .crush__title {
  @include font(15, 3, $latoBold);
  color: $red;
  margin: 0 auto 10px auto;
  max-width: 180px;
}

#map-results.leaflet-container a.crush__link {
  width: 100%;
  @media (min-width: $mq-sm) {
    display: flex;
  }
}

#map-results.leaflet-container .crush__img-wrapper {
  position: relative;
  overflow: hidden;
  height: 150px;
}
#map-results.leaflet-container .crush__img {
  position: absolute;
  width: 100%;
  max-width: 200px;
}
