/* -----------------
   DETAILS COMPONENT
   ----------------- */

.details {
  padding: 40px 0 45px 0;
  background: $greyLightest;

  @media (min-width: $mq-sm) {
    padding: 62px 0 65px 0;
  }
}

.details__title {
  @extend %title-h3--lg;
  margin-bottom: 25px;

  @media (min-width: $mq-sm) {
    text-align: center;
  }
}

.details__list {
  display: flex;
  flex-direction: column;

  @media (min-width: $mq-sm) {
    lost-flex-container: row;
    align-items: center;
  }

  address,
  p,
  a {
    @include font(14, 4, $latoBold);
    color: $greyDark;
  }
}

.details__item {
  position: relative;
  padding-left: 45px;
  margin-bottom: 20px;

  @media (min-width: $mq-sm) {
    lost-column: 1/2 2 30px;
  }

  &:last-child {
    @media (max-width: $mq-sm) {
      margin-bottom: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    display: block;
    width: 30px;
    height: 30px;
  }

  &.details__item--location {
    &:before {
      background: url('img/ico-location-green.svg') center center no-repeat;
      background-size: contain;
    }
  }

  &.details__item--tel {
    &:before {
      background: url('img/ico-tel-green.svg') center center no-repeat;
      background-size: contain;
    }
  }

  &.details__item--mail {
    &:before {
      background: url('img/ico-mail-green.svg') center center no-repeat;
      background-size: contain;
    }
  }

  &.details__item--website {
    &:before {
      background: url('img/ico-website-green.svg') center center no-repeat;
      background-size: contain;
    }
  }
}
